import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { HttpClient, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { id } from '@swimlane/ngx-charts';
import { url } from 'inspector';

export interface Mensagem {
  messaging_product: string;
  recipient_type: string;
  to: number;
  type: string;
  text: {
    body: string;
  };
}

export interface Conversa {
  mensagem: Mensagem;
  chaveConversa: string;
  statusConversa: string;
  dataHora: string;
  OrigemMsg: string;
  urlArquivo: string;
  typeArquivo: string;
  _id: string;
}

interface recoveryMessage {
  mensagem: {
    messaging_product: string,
    recipient_type: string,
    to: number,
    type: string,
    text: {
      body: string
    }
  },
  chaveConversa: string,
  statusConversa: string,
  dataHora: Date,
  OrigemMsg: string,
  urlMidia: string,
  _id: string
}

interface recoveryConversation extends Array<recoveryMessage> { }

interface Message {
  text: string;
  userName: string;
  reply: boolean;
  Date: string;
  type: string;
  files: any;
  nomeArquivo: string;
}

interface Messages extends Array<Message> { }

interface ChatMessages {
  chatId: string;
  messages: Messages
}

interface persistMessage {
  chatId: string;
  message: Message
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private _hubConnection: HubConnection;
  private chatsAbertos = new BehaviorSubject<number>(0);
  private numeroClientes = new BehaviorSubject<number>(0);
  private habilitaChat = new BehaviorSubject<boolean>(false)
  private enviandoArquivoSubject = new BehaviorSubject<boolean>(false)
  private novaMensagem = new Subject<persistMessage>();
  chatsAbertos$ = this.chatsAbertos.asObservable();
  novaMensagem$ = this.novaMensagem.asObservable();
  numero$ = this.numeroClientes.asObservable();
  public enviandoArquivo$ = this.enviandoArquivoSubject.asObservable()
  private listaChats = new Subject<any[]>()
  chat$ = this.listaChats.asObservable();
  chats: any[] = [];
  API = environment.API
  chatAPI = environment.Chat


  constructor(private http: HttpClient,
  ) {
  }

  //
  public createConnection(endPoint: string, params?, groups?): HubConnection {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(`${this.buildUrlWithParams(endPoint, params)}`,
        {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
        })
      .withAutomaticReconnect()
      .build();
    return this._hubConnection
  }

  public startConnection(hub: HubConnection): Promise<Boolean> {
    Object.defineProperty(WebSocket, 'OPEN', { value: 1, });
    return hub
      .start()
      .then((response: any) => {
        return true
      })
      .catch(err => {
        return false
      });
  }

  public stopConnection(hub: HubConnection, id: string) {
    // hub.send('DisconnectAllUserConnections', id)
    //   .then(()=> {
    //     return 'Conexão finalizada!'
    //   })
    hub.stop();
  }

  public enviaMensagem(hub: HubConnection, method: string, username: string, message: string, aplicacao: string) {
    return hub.send(method, username, message, aplicacao)
  }

  public recebeMensagem(hub: HubConnection, method: string) {
    let message
    hub.on(method, (username, text) => {
      return message = {
        text: text,
        userName: username
      }
    })
  }

  public buildUrlWithParams(hubUrl: string, params: { [key: string]: string }): string {
    if (params != null) {
      const queryString = Object.keys(params)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
        .join('&');

      return hubUrl + '?' + queryString;
    }

    else {
      return hubUrl;
    }
  }

  emitirNumeroClientes(arrayFila: any[]) {
    let arrayFilaEspera = arrayFila.filter(item => item.Status == 'Em espera')
    let arrayFilaTransferencia = arrayFila.filter(item => item.Status == 'Transferência em espera')
    this.numeroClientes.next(arrayFilaEspera.length + arrayFilaTransferencia.length);
  }

  emitirChatsAbertos(valor: number){
    this.chatsAbertos.next(valor)
  }

  getNumeroClientes() {
    return this.numeroClientes.asObservable();
  }

  emitirHabilitaChat(condicao){
    if(condicao.length > 0){
      this.habilitaChat.next(true);
    }
    else{
      this.habilitaChat.next(false);
    }
  }

  getHabilitaChat(){
    return this.habilitaChat.asObservable();
  }

  persisteChat(chat: persistMessage) {

    let chatFound = false;

    this.chats.forEach((c: ChatMessages) => {
      if (c.chatId == chat.chatId) {
        c.messages.push(chat.message);
        chatFound = true;
      }
    });

    if (!chatFound) {
      if (chat.message.type != 'text') {
        this.chats.push({
          chatId: chat.chatId,
          messages: [{
            userName: chat.message.userName,
            text: chat.message.text,
            reply: chat.message.reply,
            Date: new Date(),
            files: chat.message.files,
            type: chat.message.type
          }]
        })
      }
      else {
        this.chats.push({
          chatId: chat.chatId,
          messages: [{
            userName: chat.message.userName,
            text: chat.message.text,
            reply: chat.message.reply,
            Date: new Date(),
            type: chat.message.type
          }]
        })
      };
    }
  }

  retornaChat(conversa: Conversa[]) {
    let messagesArray = []
    conversa.forEach(message => {
      if (message.urlArquivo == '' || message.urlArquivo == null) {
        let formatMensagem = {
          text: message.mensagem.text.body,
          date: message.dataHora,
          reply: message.OrigemMsg == 'C' ? false : true,
          type: message.mensagem.type,
          user: {
            name: '',
          },
        }
        messagesArray.push(formatMensagem)
      }
      else {
        let formatMensagem = {
          date: message.dataHora,
          reply: message.OrigemMsg == 'C' ? false : true,
          type: message.typeArquivo != 'text' ? 'file' : 'text',
          user: {
            name: '',
          },
          files: message.typeArquivo != '' ? this.retornaFilesFormatHistorico(message.typeArquivo, message.urlArquivo) : null,
          customMessage: message.typeArquivo == 'audio/ogg' ? true : false
        }
        messagesArray.push(formatMensagem)
      }
    })
    return messagesArray
  }

  emitirMessage(message) {
    this.novaMensagem.next(message);
  }

  retiraChat(chatId: string) {
    this.chats == this.chats.filter(chat => { return chat.chatId !== chatId })
  }

  public requisitaChat(phoneNumberId: string, telefone: string, IdEquipeChat: string): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        telefone: telefone,
        phoneNumberId: phoneNumberId,
        idEquipeChat: IdEquipeChat
      }
    })
    return this.http.get(`${environment.Chat}/api/LogConversas`, { params: params })
  }

  public requisitaContato(listaContatos) {
    return this.http.post(`https://backendcrm.sistemasfocuscontabil.com/homologacao/api/Cliente/TelNomeContato`, listaContatos)
  }

  public enviaPrimeiraMensagem(mensagem) {
    return this.http.post(`${environment.Chat}/api/MensagemWpp/EnviaMensagemInicial`, mensagem)
  }

  public requisitaTranscricaodeAudio(nomeArquivo, telefone, phoneNumberId, posicaoChat, dataHoraAudio): Observable<any>{
    const params = new HttpParams({
      fromObject: {
        nomeArquivo: nomeArquivo,
        telefone: telefone,
        phoneNumberId: phoneNumberId,
        posicaoChat: posicaoChat,
        dataHoraAudio: dataHoraAudio
      }
    })
    return this.http.get(`${environment.Chat}/api/MensagemWpp/GetTranscricaoAudio`, {params: params})
  }

  retornaFilesFormatHistorico(type, message) {
    let files = []
      switch (type) {
        case 'image/jpeg':
          files.push({
            url: message,
            // type: type,
            icon: 'image'
          })
          break;

        case 'image/png':
          files.push({
            url: message,
            // type: type,
            icon: 'image'
          })
          break;

        case 'image/gif':
          files.push({
            url: message,
            // type: type,
            icon: 'padrao'
          })
          break;

        case 'text/csv':
          files.push({
            url: message,
            // type: message.type,
            icon: 'excel'
          })
          break;
        case 'application/pdf':
          files.push({
            url: message,
            // type: type,
            icon: 'pdf'
          })
          break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          files.push({
            url: message,
            icon: 'excel'
          })
          break;
        case 'application/msword':
          files.push({
            url: message,
            // type: message.type,
            icon: 'word'
          })
          break;

        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          files.push({
            url: message,
            // type: message.type,
            icon: 'word'
          })
          break;

        case 'audio/ogg':
          files.push({
            url: message,
            // type: message.type,
            icon: 'audio'
          })
          break;

        case 'video/mp4':
          files.push({
            url: message,
            icon: 'video'
          })
          break;

        default:
          files.push({
            url: message,
            type: message,
            icon: 'padrao'
          })
          break;
      }
      return files
  }

  statusEnviandoArquivo(value: boolean){
    this.enviandoArquivoSubject.next(value)
  }

  getConversa(id: number){
    return this.http.get(`${environment.Chat}/api/Conversas/${id}`)
  }

  postConversa(idSolicitacao: number, idConversa: string, phoneNumberId: string, telefone: string){

    let obj = {
      idProtocolo: idSolicitacao,
      idConversa: idConversa,
      phoneNumberIdProd: phoneNumberId,
      telefone: telefone
    }

    console.log(obj)
    return this.http.post(`${environment.Chat}/api/Conversas/EncerrarConversa`, obj)
  }
}
