import { filter } from 'rxjs/operators';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { NB_WINDOW_CONTEXT, NbIconLibraries, NbSidebarService, NbWindowRef } from '@nebular/theme';
import { ChatService } from '../../../../services/chat.service';
import { environment } from '../../../../environments/environment';
import { HttpResponse, HubConnection } from '@microsoft/signalr';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { HttpParams } from '@angular/common/http';
import { LoginService } from '../../../../services/login.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TrocaFilaChatComponent } from './troca-fila-chat/troca-fila-chat.component';
import { SolicitacaoComponent } from '../../componetes-comum/solicitacao/solicitacao.component';
import { PaginaPerguntaComponent } from '../../componetes-comum/pagina-pergunta/pagina-pergunta.component';
import { personalizedIcons } from './svgDocIcons';
import { PaginaErroComponent } from '../../componetes-comum/pagina-erro/pagina-erro.component';
import { EventEmitter } from 'events';
import { Subscription } from 'rxjs';

interface chatMessage {
  text: string;
  date: Date;
  reply: boolean;
  user: {
    name: string;
  }
}

interface chat {
  status: string;
  title: string;
  messages: chatMessage[];
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {

  contextStart
  interval
  iconesPersonalizados = personalizedIcons
  API = environment.Chat
  connectionChat: HubConnection;
  HubConnFila: HubConnection;
  EquipeFila
  intervalId
  usuarioLogado
  subscribeNewMessage: Subscription
  reOpenChat = []
  contato
  avatar = "https://crmfocushomologacao.sistemasfocuscontabil.com/Imagens/icone.png"
  arquivosChat = []
  NChatsAbertos
  SubChatsAbertos: Subscription
  chat: any =
    {
      status: 'success',
      title: 'Nebular Conversational UI Success',
      messages: [

      ],
    }


  constructor(@Inject(NB_WINDOW_CONTEXT) context,
    public chatService: ChatService,
    public loginService: LoginService,
    public nbWindowRef: NbWindowRef,
    public matDialog: MatDialog,
    public sideBarService: NbSidebarService,
    public iconLibraries: NbIconLibraries,) {

    this.iconLibraries.registerSvgPack('personalized', {
      'pdf': this.iconesPersonalizados.pdf,
      'excel': this.iconesPersonalizados.excel,
      'word': this.iconesPersonalizados.word,
      'audio': this.iconesPersonalizados.audio,
      'image': this.iconesPersonalizados.image,
      'video': this.iconesPersonalizados.video,
      'padrao': this.iconesPersonalizados.padrao,
    })

    this.iconLibraries.setDefaultPack('personalized')
    this.usuarioLogado = this.loginService.getUsuarioDadosCompletos();
    this.contextStart = context
    this.connectionChat = context.connection;
    this.contato = context.start
    this.EquipeFila = context.equipe

    if(this.contextStart.start.Status != 'Em atendimento'){
      let mensagemInicioPadrao = `Olá ${this.contextStart.start.NomeUsuarioWpp}.\n\nMe chamo ${this.usuarioLogado.Nome}, analista do(a) ${this.EquipeFila[0].Departamento}.\n\nEm que posso ajudar?`
      this.connectionChat.send('NewMessageFromChat', this.usuarioLogado.Nome, mensagemInicioPadrao, this.contextStart.start.Telefone, `${parseInt(this.contextStart.start.Telefone) + parseInt(this.contextStart.start.PhoneNumberIdProd)}`, this.contextStart.start.PhoneNumberIdProd, null)
      .then(() => {
        this.chatService.requisitaChat(this.contextStart.start.PhoneNumberIdProd, this.contextStart.start.Telefone, this.EquipeFila[0].IdEquipeChat)
        .toPromise()
        .then(response => {
          this.chat.messages = this.chatService.retornaChat(response)
          this.contextStart.start.Status = 'Em atendimento'
        })
      })
    }
    else{
      this.chatService.requisitaChat(this.contextStart.start.PhoneNumberIdProd, this.contextStart.start.Telefone, this.EquipeFila[0].IdEquipeChat)
        .toPromise()
        .then(response => {
          this.chat.messages = this.chatService.retornaChat(response)
        })
    }
  }

  ngOnInit(): void {
    this.sideBarService.collapse('fila-sidebar-right')

    this.SubChatsAbertos = this.chatService.chatsAbertos$.subscribe(response => {
      this.NChatsAbertos = response
    })
    // this.reOpenChat = this.chatService.retornaChat(this.contextStart.connection.baseUrl)
    // if (this.reOpenChat.length != 0) {
    //   this.reOpenChat.forEach(chat => {
    //     chat.messages.forEach(message => {
    //       if (message.type == 'text') {
    //         this.chat.messages.push({
    //           text: message.text,
    //           date: message.Date,
    //           reply: message.reply,
    //           type: message.type,
    //           user: {
    //             name: message.userName,
    //           },
    //         }
    //         )
    //       }
    //       else {
    //         this.chat.messages.push({
    //           text: message.type == 'text' ? message.text : null,
    //           date: message.Date,
    //           reply: message.reply,
    //           user: {
    //             name: message.userName,
    //           },
    //           type: message.type != 'text' ? 'file' : 'text',
    //           files: message.files,
    //         })

    //       }
    //     })
    //   })
    // }

    this.subscribeNewMessage = this.chatService.novaMensagem$.subscribe(response => {
        if (response.chatId == this.connectionChat.baseUrl) {
          this.chat.messages.push(
            {
              text: response.message.type == 'text' ? response.message.text : null,
              date: response.message.Date,
              reply: response.message.reply,
              user: {
                name: response.message.userName,
                avatar: 'https://cdn-icons-png.flaticon.com/512/6596/6596121.png'
              },
              type: response.message.type != 'text' ? 'file' : 'text',
              files: this.retornaFilesFormat(response.message),
              customMessage: response.message.type == 'audio/ogg' ? true : false
            }
          )

          if (response.message.type == 'audio/ogg') {
            let dots = '';
            const maxDots = 3;
            const intervalDuration = 500;
            const uniqueId = new Date().getTime()
            // Adiciona a mensagem de "Aguardando transcrição do audio..."
            const waitingMessage = {
              text: 'Aguardando transcrição do audio' + dots,
              date: '',
              reply: false,
              type: 'text',
              user: {
                name: response.message.userName,
                avatar: 'https://cdn-icons-png.flaticon.com/512/6596/6596121.png'
              },
              id: uniqueId
            };

            this.chat.messages.push(waitingMessage);

            // Atualiza a mensagem com pontos piscando
            const interval = setInterval(() => {
              dots += '.';
              if (dots.length > maxDots) {
                dots = '';
              }
              // Atualiza o texto da mensagem de espera
              waitingMessage.text = 'Aguardando transcrição do audio' + dots;

              // Atualiza a mensagem no chat
              this.chat.messages = [...this.chat.messages];

            }, intervalDuration);
            this.getTranscricaoAudio(response.message.nomeArquivo, this.contextStart.start.Telefone, this.contextStart.start.PhoneNumberIdProd, `${this.chat.messages.length - 1}`, response.message.Date, interval)

          }
        }
      })

  }

  getTranscricaoAudio(nomeArquivo: string, telefone: string, phoneNumberId: string, posicaoChat: string, dataHoraAudio: string, interval) {
    this.chatService.requisitaTranscricaodeAudio(nomeArquivo, telefone, phoneNumberId, posicaoChat, dataHoraAudio)
      .toPromise()
      .then((response: string) => {
        clearInterval(interval)
        this.chat.messages[posicaoChat].text = response
      })
      .catch(err => {
        clearInterval(interval)
        this.chat.messages[posicaoChat].text = err.error.text
      })
  }

  retornaFilesFormat(message) {
    let files = []
    if (message.type != 'text') {
      switch (message.type) {
        case "image/jpeg":
          files.push({
            url: message.text,
            // type: message.type,
            icon: 'image'
          })
          break;

        case 'image/png':
          files.push({
            url: message.text,
            // type: message.type,
            icon: 'image'
          })
          break;

        case 'image/gif':
          files.push({
            url: message.text,
            // type: message.type,
            icon: 'image'
          })
          break;

        case 'text/csv':
          files.push({
            url: message.text,
            // type: message.type,
            icon: 'excel'
          })
          break;
        case 'application/pdf':
          files.push({
            url: message.text,
            // type: message.type,
            icon: 'pdf'
          })
          break;
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          files.push({
            url: message.text,
            icon: 'excel'
          })
          break;
        case 'application/msword':
          files.push({
            url: message.text,
            // type: message.type,
            icon: 'word'
          })
          break;

        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          files.push({
            url: message.text,
            // type: message.type,
            icon: 'word'
          })
          break;

        case 'audio/ogg':
          files.push({
            url: message.text,
            // type: message.type,
            icon: 'audio'
          })
          break;

        case 'video/mp4':
          files.push({
            url: message.text,
            icon: 'video'
          })
          break;
          
        default:
          files.push({
            url: message.text,
            // type: message.type,
            icon: 'padrao'
          })
          break;
      }
      return files
    }
    else {
      return null;
    }
  }

  ngOnDestroy(): void {
    // this.nbWindowRef.stateChange
    // .subscribe(response => {
    //   if(response.newState == 'minimized'){
    //     this.chatService.persisteChat(this.chat)
    //   }
    // })
    this.SubChatsAbertos.unsubscribe();
    this.subscribeNewMessage.unsubscribe();
  }

  sendMessage(event) {
    if (event.files.length > 0) {
      this.processaArquivos(event.files)
        .then(response => {
          this.connectionChat.invoke('NewFileFromChat', this.usuarioLogado.Nome, event.message, this.contextStart.start.Telefone, `${parseInt(this.contextStart.start.Telefone) + parseInt(this.contextStart.start.PhoneNumberIdProd)}`, this.contextStart.start.PhoneNumberIdProd, this.arquivosChat)
            .then((res: any) => {
              if (res.statusCode == 200) {
                this.chatService.statusEnviandoArquivo(false);
                event.files.forEach((file: File) => {
                  this.chat.messages.push({
                    text: event.message,
                    date: new Date(),
                    reply: true,
                    user: {
                      name: this.usuarioLogado.Nome,
                    },
                    type: 'file',
                    files: this.retornaFilesFormat(file)
                  });
                })

                this.arquivosChat = [];
              }
              if (res.statusCode == 400) {
                this.abrirPaginaErro(res.value.message, 'interno');
                this.chatService.statusEnviandoArquivo(false);
              }
              if (res.statusCode == 409) {
                this.abrirPaginaErro(res.value.message, 'interno')
                this.chatService.statusEnviandoArquivo(false);
              }
            })
            .catch(err => {
              console.log(err)
            })
        }).catch(err => {
          this.abrirPaginaErro(err, 'erro')
        })
    }

    else {
      this.connectionChat.send('NewMessageFromChat', this.usuarioLogado.Nome, event.message, this.contextStart.start.Telefone, `${parseInt(this.contextStart.start.Telefone) + parseInt(this.contextStart.start.PhoneNumberIdProd)}`, this.contextStart.start.PhoneNumberIdProd, null)
        .then(res => {

          this.chat.messages.push({
            text: event.message,
            date: new Date(),
            reply: true,
            user: {
              name: this.usuarioLogado.Nome,
            },
          });
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  processaArquivos(files: File[]): Promise<string | any> {
    const reader = new FileReader();
    let soma = 0
    files.forEach((file: File) => {
      soma = soma + file.size
    });

    if (soma < 50000000) {
      let fileIndex = 0
      const readFile = (): Promise<void> => {
        return new Promise((resolve, reject) => {
          if (fileIndex < files.length) {
            const file = files[fileIndex];
            reader.onload = (event) => {
              const result = event.target.result as string;
              this.arquivosChat.push({
                File: result,
                Type: files[fileIndex].type,
                Name: files[fileIndex].name
              });
              fileIndex++;
              readFile()
                .then(resolve)
                .catch(reject);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          } else {
            resolve();
          }
        });
      };
      return readFile().then(() => {
        this.arquivosChat
      });
    }
    else {
      this.chatService.statusEnviandoArquivo(false);
      this.abrirPaginaErro('O tamanho máximo dos arquivos somados são 50MB!', 'interno')
    }
  }

  abrirPaginaErro(mensagem, tipo) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      mensagem: mensagem,
      tipo: tipo
    }
    this.matDialog.open(PaginaErroComponent, dialogConfig);
  }
  async AbrirPaginaPergunta() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = "Deseja encerrar o atendimento?"

    this.matDialog.open(PaginaPerguntaComponent, dialogConfig)
      .afterClosed()
      .subscribe(response => {
        console.log(response)
        if (response) {
          this.disconectChat();
        }
      })
  }

  trocarFila() {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      idUserChat: this.contextStart.start.Telefone,
      antigoGrupoName: this.contextStart.start.IdChatEquipe,
      phoneNumberId: this.contextStart.start.PhoneNumberIdProd,
      connectionFila: this.contextStart.connectionFila,
      connectionChat: this.connectionChat,
      usuarioLogado: this.usuarioLogado
    }
    dialogConfig.width = '18vw';

    this.matDialog.open(TrocaFilaChatComponent, dialogConfig)
      .afterClosed()
      .subscribe(
        response => {
          if (response) {
            this.connectionChat.send('NewMessageFromChat', this.usuarioLogado.Nome, `Seu atendimento foi transferido para a equipe *${response.nomeEquipe}*, em breve um analista entrará em contato por este número.`, this.contextStart.start.Telefone, `${parseInt(this.contextStart.start.Telefone) + parseInt(this.contextStart.start.PhoneNumberIdProd)}`, this.contextStart.start.PhoneNumberIdProd, null)
              .then(() => {
                this.connectionChat.stop();
                this.chatService.emitirChatsAbertos(this.NChatsAbertos - 1)
                this.nbWindowRef.close();
              })
          }
        }
      )
  }

  disconectChat() {
    // this.contextStart.connectionFila.send("RemovePosicaoFilaAtendimento", this.contextStart.start.PhoneNumberIdProd,`${this.contextStart.start.PhoneNumberIdProd}-${this.contextStart.start.idOpcaoApp}`, this.contextStart.start.Telefone)
    //   .then(response => {
    //     console.log(response)
    //     this.connectionChat.stop()
    //       .then(response => {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      usuarioLogado: this.usuarioLogado,
      solicitante: this.contextStart,
      component: 'chat',
      equipe: this.EquipeFila,
      connectionChat: this.connectionChat,
      conversa: this.chat.messages,
      chatComponentRef: this.nbWindowRef,
      contato: this.contato
    }
    dialogConfig.panelClass = 'solicitacao-Dialog'

    dialogConfig.disableClose = true;

    this.matDialog.open(SolicitacaoComponent, dialogConfig)
    // console.log(response)onent, dialogConfig)
    // console.log(response)
    // this.nbWindowRef.close();
    //     })
    // })

    // .catch(error => {
    //   console.log(error)
    // })
  }
}
